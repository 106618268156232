<template>
  <div class="profile">
    <b-row v-if="Object.keys(profileData).length">
      <b-col lg="4">
        <b-card>
           <div class="text-right">
            <router-link to="/profile/basic-info/edit" >
              <button class="btn btn-primary btn-sm " >
                <feather-icon icon="EditIcon" />
              </button>
            </router-link>
           </div>
          <div class="profile-img text-center">
            <b-img :src="profileData.image" />
          </div>
          <div class="profile-content text-center pt-1">
            <b-card-title style="margin-bottom: 13px">{{
              profileData.name
            }}</b-card-title>
            <b-card-sub-title>{{ profileData.designation ? profileData.designation :"Not provided"  }}</b-card-sub-title>
            <p class="mt-1">
              {{ profileData.career_objective ? profileData.career_objective :"Not provided"  }}
            </p>
          </div>

          <b-card-title class="d-flex justify-content-between pt-2"
            ><b>Preferences</b>
            <router-link to="/profile/preference/edit">
              <button class="btn btn-primary btn-sm">
                <feather-icon icon="EditIcon" />
              </button>
            </router-link>
          </b-card-title>
          <b-row>
            <b-col lg="6" class="info_inner">
              <p class="title">Seniority</p>
              <p>
                <b>{{ profileData.seniority ? profileData.seniority :"Not provided" }}</b>
              </p>
            </b-col>
            <b-col lg="6" class="info_inner">
              <p class="title">Workplace Type</p>
              <p>
                <b>{{ profileData.workplace_type ? profileData.workplace_type :"Not provided" }}</b>
              </p>
            </b-col>
            <b-col lg="6" class="info_inner">
              <p class="title">Current Salary</p>
              <p>
                <b>{{ profileData.current_salary ? profileData.current_salary :"Not provided" }}/-</b>
              </p>
            </b-col>
            <b-col lg="6" class="info_inner">
              <p class="title">Expected Salary</p>
              <p>
                <b>{{ profileData.expected_salary ? profileData.expected_salary :"Not provided" }}/-</b>
              </p>
            </b-col>
            <b-col lg="6" class="info_inner">
              <p class="title">Availability</p>
              <p>
                <b>{{ profileData.availability ? profileData.availability :"Not provided" }}</b>
              </p>
            </b-col>
          </b-row>
          <div class="info_inner">
            <p class="title">Preference Industries</p>
            <p>
              <b-badge
                v-for="(industry, index) in profileData.industries"
                :key="index"
                style="margin-right: 3px; margin-bottom: 3px"
              >
                {{ industry.name }}
              </b-badge>
            </p>
          </div>

          <div class="profile-skills pt-2">
            <b-card-title class="d-flex justify-content-between"
              ><b>Skills</b>
              <router-link to="/profile/skill/edit">
                <button class="btn btn-primary btn-sm">
                  <feather-icon icon="EditIcon" />
                </button>
              </router-link>
            </b-card-title>

            <div
              class="info_inner"
              v-for="(skill, index) in sortedSkills"
              :key="index"
            >
              <p class="title">
                {{ skill.proficiency }} -
                {{ skill.years_of_experience }}
              </p>
              <p>
                <b>{{ skill.name }}</b>
              </p>
            </div>
          </div>

          <div class="pt-2">
            <b-card-title class="d-flex justify-content-between"
              ><b>CV Lists</b>
              <router-link to="/profile/cv/edit">
                <button class="btn btn-primary btn-sm">
                  <feather-icon icon="EditIcon" />
                </button>
              </router-link>
            </b-card-title>
            <div
              class="info_inner d-flex justify-content-between align-items-center"
              v-for="(cv, index) in profileData.cvs"
              :key="index"
            >
              <p>
                <b>{{ cv.title }}</b>
              </p>
              <a
                :href="cv.cv"
                :download="cv.cv"
                class="btn btn-sm"
                :class="cv.status == true ? 'btn-success' : 'btn-secondary'"
              >
                <feather-icon icon="ArrowDownIcon" />
              </a>
            </div>
          </div>
        </b-card>
        <b-card>
          <b-card-title class="d-flex justify-content-between"
            ><b>References</b>
            <router-link to="/profile/reference/edit">
              <button class="btn btn-primary btn-sm">
                <feather-icon icon="EditIcon" />
              </button>
            </router-link>
          </b-card-title>
          <b-row>
            <b-col
              lg="6"
              cols="12"
              md="6"
              v-for="(reference, index) in references"
              :key="index"
            >
              <div>
                <div class="card-body card border">
                  <div>
                    <h5 class="card-title font-weight-bolder font-weight-bold">
                      {{ reference.name }}
                    </h5>
                  </div>

                  <div>
                    <h6 class="card-subtitle mb-1 text-secondary">
                      {{ reference.designation }}
                    </h6>
                  </div>
                  <div>
                    <h6 class="card-subtitle text-secondary">
                      {{ reference.company }}
                    </h6>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col lg="8">
        <b-card>
          <b-card-title class="d-flex justify-content-between"
            ><b>Basic Information</b>
            <router-link to="/profile/basic-info/edit">
              <button class="btn btn-primary btn-sm">
                <feather-icon icon="EditIcon" />
              </button>
            </router-link>
          </b-card-title>

          <b-row class="info_css">
            <b-col lg="3" class="info_inner">
              <p class="title">PHONE</p>
              <p>
                <b>{{ profileData.phone }}</b>
              </p>
            </b-col>
            <b-col lg="3" class="info_inner">
              <p class="title">Email</p>
              <p>
                <b>{{ profileData.email }}</b>
              </p>
            </b-col>
            <b-col lg="3" class="info_inner">
              <p class="title">On Board</p>
              <p>
                <span
                  class="badge"
                  :class="
                    profileData.onboard_status == 1
                      ? 'badge-primary'
                      : 'badge-danger'
                  "
                >
                  {{
                    (profileData.onboard_status == 1) == 1
                      ? "Completed"
                      : "In Complete"
                  }}
                </span>
              </p>
            </b-col>
            <b-col lg="3" class="info_inner">
              <p class="title">Gender</p>
              <p>
                <b>{{ profileData.gender ? profileData.gender :"Not provided" }}</b>
              </p>
            </b-col>
            <b-col lg="3" class="info_inner">
              <p class="title">Secondary phone</p>
              <p>
                <b>{{ profileData.secondary_phone ? profileData.secondary_phone :"Not provided" }}</b>
              </p>
            </b-col>
            <b-col lg="3" class="info_inner">
              <p class="title">Secondary email</p>
              <p>
                <b>{{ profileData.secondary_email ? profileData.secondary_email :"Not provided" }}</b>
              </p>
            </b-col>
            <b-col lg="3" class="info_inner">
              <p class="title">Marital Status</p>
              <p>
                <b>{{ profileData.marital_status ? profileData.marital_status :"Not provided" }}</b>
              </p>
            </b-col>
            <b-col lg="3" class="info_inner">
              <p class="title">Date Of Birth</p>
              <p>
                <b>{{ profileData.birth_date ? profileData.birth_date :"Not provided" }}</b>
              </p>
            </b-col>
            <b-col lg="3" class="info_inner">
              <p class="title">Father name</p>
              <p>
                <b>{{ profileData.father_name ? profileData.father_name :"Not provided" }}</b>
              </p>
            </b-col>
            <b-col lg="3" class="info_inner">
              <p class="title">Mother name</p>
              <p>
                <b>{{ profileData.mother_name ? profileData.mother_name :"Not provided" }}</b>
              </p>
            </b-col>
            <b-col lg="3" class="info_inner">
              <p class="title">Religion</p>
              <p>
                <b>{{ profileData.religion ? profileData.religion :"Not provided" }}</b>
              </p>
            </b-col>
            <b-col lg="3" class="info_inner">
              <p class="title">Blood Group</p>
              <p>
                <b>{{ profileData.blood_group ? profileData.blood_group :"Not provided" }}</b>
              </p>
            </b-col>
            <b-col lg="3" class="info_inner">
              <p class="title">Nationality</p>
              <p>
                <b>{{ profileData.nationality ? profileData.nationality :"Not provided" }}</b>
              </p>
            </b-col>
            <b-col lg="3" class="info_inner">
              <p class="title">Nid</p>
              <p>
                <b>{{ profileData.nid ? profileData.nid :"Not provided" }}</b>
              </p>

            </b-col>
            <b-col lg="3" class="info_inner">
              <p class="title">Passport number</p>
              <p>
                <b>{{ profileData.passport_number ? profileData.passport_number :"Not provided" }}</b>
              </p>
            </b-col>
          </b-row>
          <div class="mt-1">
            <Dropdown style="margin-left: 20px" @on-click="getPdfCv">
              <Button class="btn-primary text-white">
                Download Resume
                <Icon type="ios-arrow-down"></Icon>
              </Button>
              <template #list>
                <DropdownMenu>
                  <DropdownItem name="1">With contact</DropdownItem>
                  <DropdownItem name="2">Without contact</DropdownItem>
                </DropdownMenu>
              </template>
            </Dropdown>
          </div>
        </b-card>

        <b-card v-if="profileData.experiences">
          <div>
            <b-card-title class="d-flex justify-content-between"
              ><b>Experiences</b>
              <router-link to="/profile/experience/edit">
                <button class="btn btn-primary btn-sm">
                  <feather-icon icon="EditIcon" />
                </button>
              </router-link>
            </b-card-title>
            <app-timeline
                v-if="profileData.experiences[0].company_name"
            >
              <app-timeline-item
                v-for="(experience, index) in profileData.experiences"
                :key="index"
              >
                <div
                  class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-0"
                >
                  <h5>{{ experience.designation }}</h5>
                  <small class="text-muted" v-if="experience.duration"
                    >{{ experience.duration }} of Experience</small
                  >
                </div>
                <h5 class="mb-1">{{ experience.company_name }}</h5>
                <h6 class="card-subtitle mb-1 text-secondary">
                  ({{ experience.starting_date }} -
                  {{ experience.ending_date }})
                </h6>
                <p>{{ experience.job_description ? profileData.job_description :"Not provided" }}</p>
              </app-timeline-item>
            </app-timeline>
            <span v-else>No Experience</span>
          </div>
        </b-card>
        <b-card>
          <b-card-title class="d-flex justify-content-between"
            ><b>Education</b>
            <router-link to="/profile/education/edit">
              <button class="btn btn-primary btn-sm">
                <feather-icon icon="EditIcon" />
              </button>
            </router-link>
          </b-card-title>
          <b-row>
            <b-col
              lg="4"
              cols="12"
              md="6"
              v-for="(education, index) in profileData.educations"
              :key="index"
            >
              <div>
                <div class="card-body card border">
                  <div>
                    <h5 class="card-title font-weight-bolder font-weight-bold">
                      {{ education.institute }}
                    </h5>
                  </div>

                  <div>
                    <h6 class="card-subtitle mb-1 text-secondary">
                      {{ education.degree }}
                    </h6>
                  </div>
                  <div>
                    <h6 class="card-subtitle text-secondary">
                      {{ education.starting_date }} -
                      {{ education.ending_date }}
                    </h6>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <div v-for="(address, index) in profileData.addresses" :key="index">
            <b-card-title class="d-flex justify-content-between"
              ><b>{{ address.title }}</b>
              <router-link to="/profile/basic-info/edit">
                <button class="btn btn-primary btn-sm">
                  <feather-icon icon="EditIcon" />
                </button>
              </router-link>
            </b-card-title>
            <b-row>
              <b-col lg="3" class="info_inner">
                <p class="title">Division</p>
                <p>
                  <b>{{ address.division_name ? address.division_name :"Not provided" }}</b>
                </p>
              </b-col>
              <b-col lg="3" class="info_inner">
                <p class="title">District</p>
                <p>
                  <b>{{ address.district_name ? address.district_name :"Not provided" }}</b>
                </p>
              </b-col>
              <b-col lg="3" class="info_inner">
                <p class="title">Upazila</p>
                <p>
                  <b>{{ address.upazila_name ? address.upazila_name :"Not provided" }}</b>
                </p>
              </b-col>
              <b-col lg="3" class="info_inner">
                <p class="title">Country</p>
                <p>
                  <b>{{ address.is_country ? address.is_country :"Not provided" }}</b>
                </p>
              </b-col>
              <b-col lg="12" class="info_inner">
                <p class="title">Address</p>
                <p>
                  <b>{{ address.address ? address.address :"Not provided" }}</b>
                </p>
              </b-col>
            </b-row>
            <hr />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BImg,
  BForm,
  BButton,
  BBadge,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BImg,
    BForm,
    BButton,
    BBadge,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      profileData: [],
      cvDowloadStatus: 1,
      references: [],
    };
  },
  mounted() {
    this.getProfile();
    this.getReference();
  },
  methods: {
    getPdfCv(value) {
      this.cvDowloadStatus = value;
      axios
        .get(
          "/candidate/cv-export/" +
            this.profileData.id +
            "/" +
            this.cvDowloadStatus,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const blob = new Blob([res.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "resume.pdf";
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },
    getProfile() {
      axios
        .get("/candidate/profile")
        .then((res) => {
          this.profileData = res.data.data;
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },
    getReference() {
      axios
        .get("/candidate/reference")
        .then((res) => {
          this.references = res.data.references;
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },
  },
  computed: {
    sortedSkills() {
      const proficiencyOrder = {
        EXPERT: 0,
        EXPERIENCED: 1,
        ADVANCED: 2,
        BEGINNER: 3,
      };
      return this.profileData?.skills?.slice().sort((a, b) => {
        return (
          proficiencyOrder[a.proficiency] - proficiencyOrder[b.proficiency]
        );
      });
    },
  },
};
</script>

<style scoped>
.profile .profile-img img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.profile .profile-skills .skill,
.industries {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}
.experience-card {
  background-color: #fafafa;
}

.designation {
  color: #0c389f;
}

.highlight {
  background: #4ec76e;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
}
.cv h3 {
  color: #fff !important;
}

.info_inner {
  margin-bottom: 5px;
}
.info_inner .title {
  margin-bottom: 2px;
  color: #979797;
  font-weight: 450;
  text-transform: uppercase;
}
.experience-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background: #000;
  border-radius: 50%;
}
.experience-logo h2 {
  text-transform: uppercase;
  font-weight: bold;
  color: #ffff;
}
</style>
